import {
  abacusCsvHeader,
  abacusCsvBodyTurnover,
  abacusCsvBodyNoTurnover,
} from "../Abacus/helper";

import { exportConfig } from "../export-config";

import {
    getPaypalList,
    setPaypalBookingImportList,
    getPaypalBookingImportList,
    resetPaypalBookingImportList,
    setPaypalBookingsManuellList,
    getPaypalBookingsManuellList,
    resetPaypalBookingsManuellList,
    getFiliale,
    setFiliale,
    resetPaypalList
} from "../Paypal/getSetReset"

const negativeToPositive = (data) => {
    return data * -1
}

const getPaypalKommisionen = () => {
    const filiale = getFiliale()
    let paypalList = getPaypalList()
    paypalList.splice(0,1)
    let paypalImportList = getPaypalBookingImportList()
    let paypalManuellList = getPaypalBookingsManuellList()
    let payPalKonto = exportConfig[filiale].transferPaypal
    let spesenKonto = exportConfig[filiale].spesen

    paypalImportList = paypalList.filter(booking => booking[8] !== '0.00' && booking[8] ).map((booking, index) => ({
        ...abacusCsvBodyTurnover,
        'Record Nr': index+1,
        Datum: booking[0],
        Konto: payPalKonto,
        Gegenkonto: spesenKonto,
        Text1: `Kommision PayPal: ${negativeToPositive(booking[8])}, ${booking[0]}`,
        Betrag: negativeToPositive(booking[8])
    }))

    paypalManuellList = paypalList.filter(booking => booking[8] === '0.00' && booking[8]).map((booking, index) => ({
        ...abacusCsvBodyNoTurnover,
        'Record Nr': index+1,
        Datum: booking[0],
        Konto: '',
        Gegenkonto: '',
        Text1: `Kommision PayPal: ${booking[9]}, ${booking.date}`,
        Betrag: booking[9]
    }))


    console.log('PayPalList',paypalList)
    console.log('paypalImportList',paypalImportList)
    console.log('paypalManuellList',paypalManuellList)

    setPaypalBookingImportList(paypalImportList)
    setPaypalBookingsManuellList(paypalManuellList)

}


const runConvertion = () => {
    getPaypalKommisionen()
  }
  
  export { runConvertion }