import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Gallery from "../Gallery/Gallery";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import config from '../../config'
import moment from 'moment';

const SlideOffer = ({ slideCallback, slide }) => {
  useEffect(() => {
    if (slide.url) setHasImage(true);
    setSlideName(slide.name);
    setOfferName(slide.offer_name);
    setOfferPrice(slide.offer_price_offer);
    setOfferPriceOfficial(slide.offer_price_official);
    setProductUrl(slide.product_url);
    setShowFrom(slide.show_from);
    setShowUntil(slide.show_until);
  }, []);

  const [hasImage, setHasImage] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [slideName, setSlideName] = useState("");
  const [offerName, setOfferName] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [offerPriceOfficial, setOfferPriceOfficial] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [showFrom, setShowFrom] = useState(moment().format('YYYY-MM-DD'));
  const [showUntil, setShowUntil] = useState(moment().format('YYYY-MM-DD'));
  const [validFrom, setValidFrom] = useState(moment().format('YYYY-MM-DD'));
  const [validUntil, setValidUntil] = useState(moment().format('YYYY-MM-DD'));


  const handleChangeImage = () => {
    setHasImage(!hasImage);

    slide.url = "";
  };

  const handleShowGallery = () => {
    setShowGallery(true);
  };

  const callBackImageUrl = (imageUrl) => {
    console.log(imageUrl);
    handleChangeImage();
    slide.url = imageUrl;
    slideCallback(slide);
    setShowGallery(false);
  };

  const onChangeSlideName = (value) => {
    setSlideName(value);
    slide.name = value;
    slideCallback(slide);
  };

  const onChangeName = (value) => {
    setOfferName(value);
    slide.offer_name = value;
    slideCallback(slide);
  };

  const onChangePriceOffer = (value) => {
    setOfferPrice(value);
    slide.offer_price_offer = value;
    slideCallback(slide);
  };

  const onChangePriceOfficial = (value) => {
    setOfferPriceOfficial(value);
    slide.offer_price_official = value;
    slideCallback(slide);
  };

  const onChangeProductUrl = (value) => {
    setProductUrl(value);
    slide.product_url = value;
    slideCallback(slide);
  };

  const onChangeShowFrom = (value) => {
    setShowFrom(value);
    slide.show_from = value;
    slideCallback(slide);
  };

  const onChangeShowUntil = (value) => {
    setShowUntil(value);
    slide.show_until = value;
    slideCallback(slide);
  };

  const onChangeValidFrom = (value) => {
    setValidFrom(value);
    slide.valid_from = value;
    slideCallback(slide);
  };

  const onChangeValidUntil = (value) => {
    setValidUntil(value);
    slide.valid_until = value;
    slideCallback(slide);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        m: 1,
        width: "25ch",
      }}
    >
      <TextField
        id="offer-name"
        label="Slide Name"
        variant="outlined"
        value={slideName}
        onChange={(e) => {
          onChangeSlideName(e.target.value);
        }}
      />
      <TextField
        id="offer-name"
        label="Name"
        variant="outlined"
        value={offerName}
        onChange={(e) => {
          onChangeName(e.target.value);
        }}
      />
      <TextField
        id="offer-price-offer"
        label="Price Offer"
        variant="outlined"
        value={offerPrice}
        onChange={(e) => {
          onChangePriceOffer(e.target.value);
        }}
      />
      <TextField
        id="offer-price-official"
        label="Price Official"
        variant="outlined"
        value={offerPriceOfficial}
        onChange={(e) => {
          onChangePriceOfficial(e.target.value);
        }}
      />
      <TextField
        id="product-url"
        label="Product URL"
        variant="outlined"
        value={productUrl}
        onChange={(e) => {
          onChangeProductUrl(e.target.value);
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker 
        label="Anzeigen von"
        inputFormat="MM/dd/yyyy"
        value={showFrom}
        onChange={(x) => onChangeShowFrom(moment(x).format('YYYY-MM-DD'))}
        renderInput={(params) => <TextField {...params} />}/>
        <DesktopDatePicker 
        label="Anzeigen bis"
        inputFormat="MM/dd/yyyy"
        value={showUntil}
        onChange={(x) => onChangeShowUntil(moment(x).format('YYYY-MM-DD'))}
        renderInput={(params) => <TextField {...params} />}/>
        {/*<DesktopDatePicker 
        label="Valide von"
        inputFormat="MM/dd/yyyy"
        value={validFrom}
        onChange={(x) => onChangeValidFrom(moment(x).format('YYYY-MM-DD'))}
        renderInput={(params) => <TextField {...params} />}/>
        <DesktopDatePicker 
        label="Valide bis"
        inputFormat="MM/dd/yyyy"
        value={validUntil}
        onChange={(x) => onChangeValidUntil(moment(x).format('YYYY-MM-DD'))}
        renderInput={(params) => <TextField {...params} />}/>*/}
      </LocalizationProvider>
      {hasImage ? (
        <ImageListItem>
          <img
            className="slide-img"
            src={`${config.backendURL}/${slide.url}?w=164&h=164&fit=crop&auto=format`}
            alt={slide.name}
            loading="lazy"
          />
          <ImageListItemBar
            sx={{
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            }}
            position="top"
            actionIcon={
              <IconButton sx={{ color: "white" }} onClick={handleChangeImage}>
                <DeleteOutlineIcon />
              </IconButton>
            }
            actionPosition="left"
          />
        </ImageListItem>
      ) : (
        <Box onClick={handleShowGallery} className="slide-add-img">
          <Typography>Click to add Image</Typography>
          <AddIcon />
        </Box>
      )}
      {showGallery && <Gallery callBack={callBackImageUrl} isSelector="true" />}
    </Stack>
  );
};

export default SlideOffer;
