// OP-Liste
let opList = [];

const setOpList = (data) => {
    opList = data;
}


const getOpList = () => {
    return opList;
}

const resetOpList = () => {
    opList = []
}

// Nab Bank Datei
let nabList = [];

const setNabList = (data) => {
    nabList = data;
}

const getNabList = (data) => {
    return nabList;
}

const resetNabList = () => {
    nabList = []
}



// Nab Buchungen für Abgleich
let nabBookingsMatchingBillingNumberList = [];

const setNabBookingsMatchingBillingNumberList = (data) => {
    nabBookingsMatchingBillingNumberList = data;
}

const getNabBookingsMatchingBillingNumberList = (data) => {
    return nabBookingsMatchingBillingNumberList;
}

const resetNabBookingsMatchingBillingNumberList = () => {
    nabBookingsMatchingBillingNumberList = []
}

// Nab Buchungen für Import
let nabBookingsImportList = [];

const setNabBookingsImportList = (data) => {
    nabBookingsImportList = data;
}

const getNabBookingsImportList = (data) => {
    return nabBookingsImportList;
}

const resetNabBookingsImportList = () => {
    nabBookingsImportList = []
}



// Nab Buchungen Restlich
let nabBookingsNoMatchList = [];

const setNabBookingsNoMatchList = (data) => {
    nabBookingsNoMatchList = data;
}

const getNabBookingsNoMatchList = (data) => {
    return nabBookingsNoMatchList;
}

const resetNabBookingsNoMatchList = () => {
    nabBookingsNoMatchList = []
}

// Filiale
let filiale = ''
const getFiliale = () => {
    return filiale
}

const setFiliale = (data) => {
    resetOpList()
    resetNabList()
    resetNabBookingsMatchingBillingNumberList()
    resetNabBookingsImportList()
    resetNabBookingsNoMatchList()
    filiale = data
}

module.exports = {
    // OP Liste
    setOpList: setOpList,
    resetOpList: resetOpList,
    getOpList: getOpList,
    // Nab Bank Datei
    setNabList: setNabList,
    resetNabList: resetNabList,
    getNabList: getNabList,
    // Nab Buchungen für Abgleich
    setNabBookingsMatchingBillingNumberList: setNabBookingsMatchingBillingNumberList,
    resetNabBookingsMatchingBillingNumberList: resetNabBookingsMatchingBillingNumberList,
    getNabBookingsMatchingBillingNumberList: getNabBookingsMatchingBillingNumberList,
    // Nab Buchungen für Import
    setNabBookingsImportList: setNabBookingsImportList,
    resetNabBookingsImportList: resetNabBookingsImportList,
    getNabBookingsImportList: getNabBookingsImportList,
    // Nab Buchungen Restlich
    setNabBookingsNoMatchList: setNabBookingsNoMatchList,
    resetNabBookingsNoMatchList: resetNabBookingsNoMatchList,
    getNabBookingsNoMatchList: getNabBookingsNoMatchList,
    // Filiale
    getFiliale: getFiliale,
    setFiliale: setFiliale
}