import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import TabPanel from '../TabPanel';

import CashPoint from '../Cashpoint/CashPoint';
import BankNab from './BankNab';
import BankPostFinance from './BankPostFinance';
import Paypal from './Paypal';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

const BankParser = () => {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <div>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="NAB" {...a11yProps(0)} />
            <Tab label="PostFinance" {...a11yProps(1)} />
            <Tab label="PayPal" {...a11yProps(2)} />
            <Tab label="Kasse" {...a11yProps(3)} />
            {/*<Tab label="Konten" {...a11yProps(2)} />
            <Tab label="Einstellungen" {...a11yProps(3)} />*/}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <BankNab/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BankPostFinance/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Paypal/>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CashPoint/>
        </TabPanel>

      </div>
    );

}

export default BankParser