import React from "react";
import NavComplete from "../Navigation/NavComplete";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../TabPanel";
import SlideShowList from "./SlideShowList";
import SlideShowEdit from "./SlideShowEdit";
import PropTypes from "prop-types";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const SlideShowOverview = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="slide">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Slide Shows" {...a11yProps(0)} />
            <Tab label="Slide Show Bearbeiten" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SlideShowList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SlideShowEdit />
        </TabPanel>
      </div>
  );
};

export default SlideShowOverview;
