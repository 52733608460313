import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from './../../assets/img/kafishop-tasse-invertiert-weiss.svg'
const TopBar = () => {

    return(
        <AppBar position="static" className="top-bar">
            <Toolbar>
                {/*<div className="logo" style={{backgroundImage: 'url(' + logo + ')'}}>*/}
                <div className="logo">
                    
                </div>
                <Typography variant="h6" className="shop-name">
                    Kafi-Shop Toolbox
                </Typography>
            </Toolbar>
        </AppBar>
    )

}

export default TopBar