import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { CSVReader } from 'react-papaparse'


function CSVReaderOne({name, setDataFunction, resetDataFunction, encoding}) {
  const buttonRef = React.createRef()

  let csvData = []

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point 
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }
  
  const handleOnFileLoad = (data) => {
    console.log(data)
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    resetDataFunction(data)
    csvData = []
  }

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }

    return (
      <CSVReader
        ref={buttonRef}
        onFileLoad={handleOnFileLoad}
        onError={handleOnError}
        noClick
        noDrag
        config={{
          encoding: encoding,
          quoteChar: '"',
          escapeChar: '',
          step: (results, parser) => {
            csvData.push(results.data)
          },
          complete: (results, file) => {
            setDataFunction(csvData)
          }
        }}
        onRemoveFile={handleOnRemoveFile}
      >
        {({ file }) => (
          <aside
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 10,
              marginTop: 10
            }}
          >
            <Chip
              type='button'
              onClick={handleOpenDialog}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: '200px',
                paddingLeft: 0,
                paddingRight: 0,
                background:"white",
                borderColor: "#8bc34a",
                color: "#8bc34a"
              }}
              variant="outlined"
              label={name}
            />
            <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10,

            }}
            >
            <div
              style={{
                borderWidth: 1,
                borderLeft: 0,
                borderRight: 0,
                borderTop: 0,
                borderStyle: 'solid',
                borderColor: '#ccc',
                height: 25,
                lineHeight: 1,
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 13,
                paddingTop: 3,
                width: '180px',
                overflow: 'hidden'
              }}
            >
              {file && file.name}
            </div>
            <IconButton aria-label="delete" onClick={handleRemoveFile}>
              <DeleteIcon fontSize="small"/>
            </IconButton>
            </div>
          </aside>
        )}
      </CSVReader>
    )
}

export default CSVReaderOne