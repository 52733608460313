import {
  abacusCsvHeader,
  abacusCsvBodyTurnover,
  abacusCsvBodyNoTurnover,
} from "../Abacus/helper";
import {
  getOpList,
  getNabList,
  setNabBookingsMatchingBillingNumberList,
  setNabBookingsImportList,
  setNabBookingsNoMatchList,
  getFiliale
} from './getSetReset'
import { exportConfig } from '../export-config';
import config from '../../../config';

let nabBookingsMatchingBillingNumber = [];
let nabBookingsNoMatchList = [];
let nabBookingsImportList = [];
let opList = [];
let nabList = [];

const prepareNabList = () => {
  for (
    let nabListItemIndex = 0;
    nabListItemIndex < nabList.length;
    nabListItemIndex++
  ) {
    if (nabList[nabListItemIndex][0].toLowerCase().includes("buchungsdatum")) {
      // CSV Start (nabListItemIndex + 1) und CSV Ende (nabList.length -1) aus nabList entfernen
      nabList = nabList.slice(nabListItemIndex + 1, nabList.length - 1);
    }
  }

  // Negative Kontobewegungen zu nabBookingsNoMatchList
  const nabBookingsNoMatchListTemp = nabList.filter((item) => item[2] !== "");

  // Negative Kontobewegungen als negativ deklarieren ('-')
  nabBookingsNoMatchList.forEach((element) => {
    element[2] = `-${element[2]}`;
    element[5] = "";
  });
  // Negative Kontobewegungen aus nabList löschen
  //nabList = nabList.filter((item) => item[2] === "");
};

// Nab Buchungen für Import
const sixPaymentPartner = (amount, bookingText, date, filiale) => {
  const startOfPaymentName = bookingText.toLowerCase().indexOf("zuerich,") + 8;
  const startOfKommision = bookingText.toLowerCase().indexOf("/kom.,") + 6;
  const endOfKomission = bookingText.toLowerCase().indexOf("/dat.");
  const startOfTransNumber = bookingText.toLowerCase().indexOf("/nr.") + 1;
  const endOfTransNumber = bookingText.toLowerCase().indexOf("/kom.,");
  const transNumber = bookingText.substring(
    startOfTransNumber,
    endOfTransNumber
  );
  const paymentType = bookingText
    .substring(startOfPaymentName, startOfPaymentName + 5)
    .toLowerCase();
  const komision = parseFloat(
    bookingText.toLowerCase().substring(startOfKommision, endOfKomission)
  );
  // Booking
  const newBookingObj = { ...abacusCsvBodyNoTurnover };
  newBookingObj["Datum"] = date;
  newBookingObj["Betrag"] = amount;
  newBookingObj["Text2"] = `${transNumber} ${date}`;

  // Komission
  const newKomissionObj = { ...abacusCsvBodyTurnover }
  newKomissionObj['Datum'] = date;
  newKomissionObj['Betrag'] = komision
  newKomissionObj['Text2'] = `Kom. ${transNumber} ${date}`;


  if (filiale === 'kafishop') {
    const spesen = exportConfig.kafishop.spesen;
    const bank = exportConfig.kafishop.nab;
    newBookingObj['Gegenkonto'] = bank;
    newKomissionObj['Gegenkonto'] = spesen;

    if (paymentType.includes("ecdir")) {
      const transfer = exportConfig.kafishop.transferEc; //10901;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung EC: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision EC: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("vpay")) {
      const transfer = exportConfig.kafishop.transferVisa; //10903;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VPay: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VPay: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("ecamc")) {
      const transfer = exportConfig.kafishop.transferMastercard; //10904;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung MasterCard: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision MasterCard: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("maes")) {
      const transfer = exportConfig.kafishop.transferEc; //10901;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung EC: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision EC: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("visa")) {
      const transfer = exportConfig.kafishop.transferVisa; //10903;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VISA: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VISA: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("dmc")) {
      const transfer = exportConfig.kafishop.transferMastercard; //10904;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung DMC: ${amount}`;


      newKomissionObj['Konto'] = transfer;
      newKomissionObj['Text1'] = `WORLDLINE Kommision DMC: ${komision}`
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;


    }
    else if (paymentType.includes("vsdb")) {
      const transfer = exportConfig.kafishop.transferVisa; //10903;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VSDB: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VSDB: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;



    }
    else if (paymentType.includes("twint")) {
      const transfer = exportConfig.kafishop.transferTwint; //10902;


      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung TWINT: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision TWINT: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    }



  } else if (filiale === 'jurastore') {
    const spesen = exportConfig.jurastore.spesen; //6900
    const bank = exportConfig.jurastore.nab; //1025
    newBookingObj['Gegenkonto'] = bank;
    newKomissionObj['Gegenkonto'] = spesen;
    if (paymentType.includes("ecdir")) {
      const transfer = exportConfig.jurastore.transferEc; //10951;
      newBookingObj['Konto'] = transfer;
      newBookingObj['Text1'] = `WORLDLINE Zahlung EC: ${amount}`

      newKomissionObj['Konto'] = transfer;
      newKomissionObj['Text1'] = `WORLDLINE Kommision EC: ${komision}`
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;


    } else if (paymentType.includes("vpay")) {
      const transfer = exportConfig.jurastore.transferVisa; //10953;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VPay: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VPay: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("ecamc")) {
      const transfer = exportConfig.jurastore.transferMastercard; //10954;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung MasterCard: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision MasterCard: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("maes")) {
      const transfer = exportConfig.jurastore.transferEc; //10951;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung EC: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision EC: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("visa")) {
      const transfer = exportConfig.jurastore.transferVisa; //10953;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VISA: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VISA: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    } else if (paymentType.includes("dmc")) {
      const transfer = exportConfig.jurastore.transferMastercard; //10954;

      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung DMC: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision DMC: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;



    }
    else if (paymentType.includes("vsdb")) {
      const transfer = exportConfig.jurastore.transferVisa; //10953;


      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung VSDB: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision VSDB: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;



    }
    else if (paymentType.includes("twint")) {
      const transfer = exportConfig.jurastore.transferTwint; //10952;


      newBookingObj["Konto"] = transfer;
      newBookingObj["Text1"] = `WORLDLINE Zahlung TWINT: ${amount}`;

      newKomissionObj["Konto"] = transfer;
      newKomissionObj["Text1"] = `WORLDLINE Kommision TWINT: ${komision}`;
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    }
  }



  nabBookingsImportList.push(newBookingObj);
  nabBookingsImportList.push(newKomissionObj);
};

const twint = (amount, bookingText, date, filiale) => {
  const kommisionStart = bookingText.toLowerCase().indexOf("fees: -") + 7;
  const kommisionEnde = kommisionStart + 4;

  const komision = parseFloat(
    bookingText.substring(kommisionStart, kommisionEnde)
  );
  const indexFirtsComma = bookingText.indexOf(",") + 1;
  bookingText = bookingText.substring(
    indexFirtsComma,
    bookingText.toLowerCase().indexOf("merchant-b1")
  );

  // Booking
  const newBookingObj = { ...abacusCsvBodyNoTurnover };
  newBookingObj["Datum"] = date;
  newBookingObj["Betrag"] = amount;
  newBookingObj["Text2"] = `${bookingText} ${date}`;

  // Komission
  const newKomissionObj = { ...abacusCsvBodyTurnover }
  newKomissionObj['Datum'] = date;
  newKomissionObj['Betrag'] = komision
  newKomissionObj['Text2'] = `Kom. ${bookingText} ${date}`;

  if (filiale === 'kafishop') {
    const transfer = exportConfig.kafishop.transferTwint; //10902
    const spesen = exportConfig.kafishop.spesen; //6900
    const bank = exportConfig.kafishop.nab; //1020

    newBookingObj['Gegenkonto'] = bank;
    newBookingObj['Konto'] = transfer;
    newBookingObj['Text1'] = `Twint Eingang: ${amount}`

    newKomissionObj['Gegenkonto'] = spesen;
    newKomissionObj['Konto'] = transfer;
    newKomissionObj['Text1'] = `Twint Kommision: ${komision}`
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;




  } else if (filiale === 'jurastore') {
    const transfer = exportConfig.jurastore.transferTwint; //10952
    const spesen = exportConfig.jurastore.spesen; //6900
    const bank = exportConfig.jurastore.nab; //1025


    newBookingObj["Gegenkonto"] = bank;
    newBookingObj["Konto"] = transfer;
    newBookingObj["Text1"] = `Twint Eingang: ${amount}`;

    newKomissionObj["Gegenkonto"] = spesen;
    newKomissionObj["Konto"] = transfer;
    newKomissionObj["Text1"] = `Twint Kommision: ${komision}`;
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

  } 


  nabBookingsImportList.push(newBookingObj);
  nabBookingsImportList.push(newKomissionObj);
};

const bsPayonePartner = (amount, bookingText, date, filiale) => {
  const kommisionStart = bookingText.toLowerCase().indexOf(",-serv ") + 6;
  const kommisionEnde = bookingText.toLowerCase().indexOf("+0vh ");
  const kommision = parseFloat(
    bookingText.substring(kommisionStart, kommisionEnde).replace(",", ".")
  );
  const indexFirtsComma = bookingText.indexOf(",") + 1;
  bookingText =
    bookingText.substring(
      indexFirtsComma,
      bookingText.indexOf(",", indexFirtsComma)
    ) +
    ", " +
    bookingText.substring(
      bookingText.toLowerCase().indexOf("einreich"),
      bookingText.toLowerCase().indexOf(",-serv ")
    );

  // Booking
  const newBookingObj = { ...abacusCsvBodyNoTurnover };
  newBookingObj["Datum"] = date;
  newBookingObj["Betrag"] = amount;
  newBookingObj["Text2"] = `${bookingText} ${date}`;

  // Komission
  const newKomissionObj = { ...abacusCsvBodyTurnover };
  newKomissionObj["Datum"] = date;
  newKomissionObj["Betrag"] = kommision;
  newKomissionObj["Text2"] = `Kom. ${bookingText} ${date}`;

  if (filiale === 'kafishop') {
    const transfer = exportConfig.kafishop.transferPayone; //10907;
    const spesen = exportConfig.kafishop.spesen; //6900;
    const bank = exportConfig.kafishop.nab; //1020;


    newBookingObj["Gegenkonto"] = bank;
    newBookingObj["Konto"] = transfer;
    newBookingObj["Text1"] = `Payone Eingang: ${amount}`;

    newKomissionObj['Gegenkonto'] = spesen;
    newKomissionObj['Konto'] = transfer;
    newKomissionObj['Text1'] = `Payone Kommision: ${kommision}`
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;


  } else if (filiale === 'jurastore') {
    const transfer = exportConfig.jurastore.transferPayone //10957;
    const spesen = exportConfig.jurastore.spesen; //6900;
    const bank = exportConfig.jurastore.nab; //1025;


    newBookingObj["Gegenkonto"] = bank;
    newBookingObj["Konto"] = transfer;
    newBookingObj["Text1"] = `Payone Eingang: ${amount}`;

    newKomissionObj["Gegenkonto"] = spesen;
    newKomissionObj["Konto"] = transfer;
    newKomissionObj["Text1"] = `Payone Kommision: ${kommision}`;
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

  }


  nabBookingsImportList.push(newBookingObj);
  nabBookingsImportList.push(newKomissionObj);
};

const swissbillingPartner = (amount, bookingText, date, filiale) => {
  if (filiale === 'kafishop') {
    const transfer = exportConfig.kafishop.transferPayone //10907;
    const spesen = exportConfig.kafishop.spesen //6900;
    const bank = exportConfig.kafishop.nab //1020;


    const kommision = (parseFloat(amount) / 97.5) * 2.5;
    if (bookingText.toLowerCase().incldues("décompte")) {
      bookingText = bookingText.substring(
        bookingText.toLowerCase().indexOf("décompte")
      );
    } else if (bookingText.toLowerCase().incldues("decompte")) {
      bookingText = bookingText.substring(
        bookingText.toLowerCase().indexOf("decompte")
      );
    }

    // Booking
    const newBookingObj = { ...abacusCsvBodyNoTurnover };
    newBookingObj["Datum"] = date;
    newBookingObj["Betrag"] = amount;
    newBookingObj["Text2"] = `${bookingText} ${date}`;
    newBookingObj["Gegenkonto"] = bank;
    newBookingObj["Konto"] = transfer;
    newBookingObj["Text1"] = `Swissbilling Eingang: ${amount}`;

    // Komission
    const newKomissionObj = { ...abacusCsvBodyTurnover };
    newKomissionObj["Datum"] = date;
    newKomissionObj["Betrag"] = kommision;
    newKomissionObj["Text2"] = `Kom. ${bookingText} ${date}`;
    newKomissionObj["Gegenkonto"] = spesen;
    newKomissionObj["Konto"] = transfer;
    newKomissionObj["Text1"] = `Swissbilling Kommision: ${kommision}`;
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;




    nabBookingsImportList.push(newBookingObj);
    nabBookingsImportList.push(newKomissionObj);
  }
};

const swisscardPartner = (amount, bookingText, date, filiale) => {
  const bookingTextLowerCase = bookingText.toLowerCase()
  console.log(bookingTextLowerCase)


  const kommisionStart = bookingTextLowerCase.indexOf('/di-') + 4
  const kommision = parseFloat(bookingText.substring(kommisionStart, bookingText.length - 1).replaceAll(" ", "").replaceAll(",", ""));
  bookingText = "NR." + bookingText.substring(bookingTextLowerCase.indexOf("vu-nr") + 7, bookingTextLowerCase.indexOf("ref.dat")).replaceAll(" ", "");
  // Booking
  const newBookingObj = { ...abacusCsvBodyNoTurnover };
  newBookingObj["Datum"] = date;
  newBookingObj["Betrag"] = amount;
  newBookingObj["Text2"] = `${bookingText} ${date}`;

  // Komission
  const newKomissionObj = { ...abacusCsvBodyTurnover };
  newKomissionObj["Datum"] = date;
  newKomissionObj["Betrag"] = kommision;
  newKomissionObj["Text2"] = `Kom. ${bookingText} ${date}`;

  if (filiale === 'kafishop') {
    const transfer = exportConfig.kafishop.transferMastercard; //10955; 
    const spesen = exportConfig.kafishop.spesen; //6900;
    const bank = exportConfig.kafishop.nab; //1025;

    newBookingObj['Gegenkonto'] = bank;
    newBookingObj['Konto'] = transfer;
    newBookingObj['Text1'] = `Swisscard Eingang: ${amount}`

    newKomissionObj['Gegenkonto'] = spesen;
    newKomissionObj['Konto'] = transfer;
    newKomissionObj['Text1'] = `Swisscard Kommision: ${kommision}`
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;


  } else if (filiale === 'jurastore') {
    const transfer = exportConfig.jurastore.transferMastercard //10905;
    const spesen = exportConfig.jurastore.spesen //6900;
    const bank = exportConfig.jurastore.nab //1020;

    newBookingObj['Gegenkonto'] = bank;
    newBookingObj['Konto'] = transfer;
    newBookingObj['Text1'] = `Swisscard Eingang: ${amount}`

    newKomissionObj['Gegenkonto'] = spesen;
    newKomissionObj['Konto'] = transfer;
    newKomissionObj['Text1'] = `Swisscard Kommision: ${kommision}`

    newKomissionObj["Gegenkonto"] = spesen;
    newKomissionObj["Konto"] = transfer;
    newKomissionObj["Text1"] = `Payone Kommision: ${kommision}`;
    newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

  }


  nabBookingsImportList.push(newBookingObj);
  nabBookingsImportList.push(newKomissionObj);
};

// Zalungseingänge auf Zahlungspartner Keyword test und an Funktionen verteilen
const checkNabBookingsImportList = () => {
  const filiale = getFiliale();
  nabList.forEach((payment) => {
    const date = payment[0];
    const bookingText = payment[1].substring(
      payment[1].indexOf(",") + 1,
      payment[1].length
    );
    const amount = payment[3] !== "" ? payment[3] : `-${payment[2]}`;

    if (bookingText.toLowerCase().includes("worldline")) {
      console.log(bookingText)
      sixPaymentPartner(amount, bookingText, date, filiale);
    } else if (bookingText.toLowerCase().includes("twint acquiring ag")) {
      twint(amount, bookingText, date, filiale);
    } else if (bookingText.toLowerCase().includes("payone gmbh")) {
      bsPayonePartner(amount, bookingText, date, filiale);
    } else if (bookingText.toLowerCase().includes("swissbilling")) {
      swissbillingPartner(amount, bookingText, date, filiale);
    } else if (bookingText.toLowerCase().includes("swisscard aecs")) {
      swisscardPartner(amount, bookingText, date, filiale);
    } else {
      const newBookingObj = { ...abacusCsvBodyTurnover };
      newBookingObj["Datum"] = date;
      newBookingObj["Betrag"] = amount;
      newBookingObj["Text2"] = `${payment[1]} ${date}`;


      nabBookingsNoMatchList.push(newBookingObj);
    }
  });
  console.log(nabBookingsImportList);
  console.log(nabBookingsNoMatchList);

  nabBookingsImportList = nabBookingsImportList.map((booking, index) => {
    booking['Record Nr'] = index + 1
    booking['Text1'] = booking['Text1'].substring(0, (booking['Text1'].length -1) < exportConfig.bookingTextMaxLength ? (booking['Text1'].length -1) : exportConfig.bookingTextMaxLength )
    booking['Text2'] = booking['Text2'].substring(0, (booking['Text2'].length -1) < exportConfig.bookingTextMaxLength ? (booking['Text2'].length -1) : exportConfig.bookingTextMaxLength )

    return booking
  })


  nabBookingsNoMatchList = nabBookingsNoMatchList.map((booking, index) => {
    booking['Record Nr'] = index + 1;
    return booking;
  });

  setNabBookingsImportList(nabBookingsImportList);
  setNabBookingsNoMatchList(nabBookingsNoMatchList);
};

const runConvertion = () => {
  nabList = getNabList();
  prepareNabList();
  checkNabBookingsImportList();
  nabBookingsMatchingBillingNumber = [];
  nabBookingsNoMatchList = [];
  nabBookingsImportList = [];
  opList = [];
  nabList = [];
};

export { runConvertion };
