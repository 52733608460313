import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import axios from "axios";
import isAuth from "../../functions/auth/isAuth";
import { useSelector, useDispatch } from 'react-redux';
import { setFeedback } from './../../actions';
import config from '../../config'

const Gallery = ({ callBack, isSelector = false }) => {
  const [imageData, setImageData] = useState([]);
  const [imageChanges, setImageChanges] = useState(0);
  const dispatch = useDispatch();


  useEffect(() => {
    axios
      .get("api/file/")
      .then((resp) => {
        setImageData(resp.data);
        console.log(resp.data);
      })
      .catch((err) => {
        isAuth(err);
        dispatch(setFeedback(`Fehler beim Laden der Galerie`, true,'error'));

        console.log(err);
      });
  }, [imageChanges]);

  const deleteImage = (id) => {
    if (id) {
      const url = "api/file/" + id;
      axios
        .delete(url)
        .then((resp) => {
          setImageChanges(imageChanges + 1);
          dispatch(setFeedback(`Datei (${id}) erfolgreich gelöscht`, true,'success'));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setFeedback(`Fehler beim Löschen der Datei (${id})`, true,'error'));
        });
    }
  };

  return (
    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
      {imageData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${config.backendURL}/${item.url}?w=164&h=164&fit=crop&auto=format`}
            //srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item.name}
            loading="lazy"
            key={item.id}
            onClick={() => {
              console.log("test");
            }}
          />
          <ImageListItemBar
            sx={{
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            }}
            title={item.title}
            position="top"
            actionIcon={
              <IconButton
                sx={{ color: "white" }}
                aria-label={`star ${item.title}`}
              >
                {isSelector === false ? (
                  <DeleteOutlineIcon onClick={() => deleteImage(item.id)} />
                ) : (
                  <AddIcon onClick={() => callBack(item.url)} />
                )}
              </IconButton>
            }
            actionPosition="left"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default Gallery;
