import React from 'react'

const ShopStatistics = () => {

    return (
        <div>Shop Statistik</div>
    )

}

export default ShopStatistics