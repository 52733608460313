import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import isAuth from "../../functions/auth/isAuth";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SlideAccordion from "./SlideAccordion";
import { useSelector, useDispatch } from 'react-redux';
import { setFeedback } from './../../actions';

const SlideShowEdit = () => {
  const [slideShows, setSlideShows] = useState([]);
  const [slideShow, setSlideShow] = useState({});
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => {
    axios
      .get("api/slide-show/")
      .then((resp) => {
        console.log(resp.data);
        setSlideShows(resp.data);
      })
      .catch((err) => {
        isAuth(err);
        console.log(err);
        dispatch(setFeedback(`Fehler beim Laden der Slide-Shows vom Server`, true,'error'));

      });
  }, []);

  useEffect(() => {
    if (Object.keys(slideShow).length > 0) {
      axios
        .get("api/slide-show/" + slideShow.id)
        .then((resp) => {
          setSlides(resp.data.Slides);
        })
        .catch((err) => {
          isAuth(err);
          console.log(err);
          dispatch(setFeedback(`Fehler beim Laden der Slides vom Server`, true,'error'));
        });
    }
  }, [slideShow]);

  const addEmptySlide = () => {
    const slideTemp = {
      SlideShowId: slideShow.id
    }
    setSlides([...slides,slideTemp])
  }

  const handleChange = (event) => {
    const id = event.target.value;
    const result = slideShows.filter((slideShow) => slideShow.id === id);
    setSlideShow(result[0]);
  };

  const callBackDeleteSlide = (value) => {
    console.log(value)
    const slidesTemp = slides.filter(slide => slide.id !== value )
    setSlides(slidesTemp);
  }

  return (
    <Stack
      spacing={2}
      sx={{
        m: 1,
        width: "60ch",
      }}
    >
      <Typography variant="h1">Slide Show Bearbeiten</Typography>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Slide Show</InputLabel>
        <Select
          value={slideShow.id}
          labelId="demo-simple-select-label"
          label="Slide Show"
          onChange={handleChange}
        >
          {slideShows.map((slideShow) => {
            return <MenuItem value={slideShow.id}>{slideShow.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
      {slides.map((slide) => {
        return <SlideAccordion slide={slide} callBackDeleteSlide={callBackDeleteSlide} key={slide.id}/>;
      })}
      {slideShow.id !== undefined && slideShow.id !== '' ?
          <Fab onClick={addEmptySlide} color="primary" aria-label="add">
            <AddIcon />
          </Fab>
      : null}

    </Stack>
  );
};

export default SlideShowEdit;
