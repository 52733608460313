import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import CSVReaderOne from '../CSVReader';
import { runConvertion } from '../../functions/BankParser/Paypal/PaypalFunctions';
import {
  setFiliale,
  setPaypalList,
  resetPaypalList,
  getPaypalList,
  getPaypalBookingImportList,
  getPaypalBookingsManuellList,
} from '../../functions/BankParser/Paypal/getSetReset';

import { exportToExcel } from '../../functions/BankParser/excelExport';

export default function () {
  const [filiale, setFilialeHandler] = useState('');

  const timeStamp = moment().unix();

  const handleFiliale = (event) => {
    setFilialeHandler(event.target.value);
    setFiliale(event.target.value);
  };

  const [paypalBookingsImportList, setPayPalBookingsImportList] = useState([]);

  const convertData = () => {
    runConvertion();
    getData();
  };

  const getData = () => {
    setPayPalBookingsImportList(getPaypalBookingImportList());
  };

  return (
    <div>
      <Typography variant="h1">Paypal CSV Dateien</Typography>
      <Box display="flex" flexDirection="column" className="bank-inner">
        <FormControl className="form-control">
          <InputLabel id="demo-simple-select-label">Filiale</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={filiale} onChange={handleFiliale}>
            <MenuItem value={'kafishop'}>Kafi-Shop</MenuItem>
            <MenuItem value={'jurastore'}>Jura Store</MenuItem>
          </Select>
        </FormControl>
        <CSVReaderOne
          name="PayPal CSV"
          setDataFunction={setPaypalList}
          resetDataFunction={resetPaypalList}
          encoding="utf-8"
        />
        <Button variant="contained" component="label" className="button" color="primary" onClick={() => convertData()}>
          Dateien Konvertieren
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" className="box-download">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) => exportToExcel(paypalBookingsImportList, `${filiale}_PayPal_Buchungen_für_Import${timeStamp}`)}
        >
          <IconButton color="primary" aria-label="upload picture" component="span">
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>Paypal Buchungen - Import {filiale}</Typography>
        </Box>
      </Box>
    </div>
  );
}
