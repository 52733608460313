import React, { useState } from 'react';
import NavComplete from './components/Navigation/NavComplete';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { setFeedback } from './actions';
import Alert from '@mui/material/Alert';
import config from './config'
import axios from 'axios';


axios.defaults.baseURL = config.backendURL;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.withCredentials = true;


function App() {
  const dispatch = useDispatch();

  const feedbackMessage = useSelector((state) => state.feedback.message);
  const feedbackOpen = useSelector((state) => state.feedback.isOpen);
  const feedBacktype = useSelector((state) => state.feedback.type);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setFeedback('', false,'success'));
  };

  return (
    <div className="app">
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar className="feedback-snackbar" open={feedbackOpen} autoHideDuration={3000} onClose={handleClose}>
          <Alert variant="filled" onClose={handleClose} severity={feedBacktype} color={feedBacktype} sx={{ width: '100%' }}>
            {feedbackMessage}
          </Alert>
        </Snackbar>

      </Stack>
      <NavComplete />
    </div>
  );
}

export default App;
