// Nab Bank Datei
let postFinanceList = [];

const setPostFinanceList = (data) => {
    postFinanceList = data;
}

const getPostFinanceList = (data) => {
    return postFinanceList;
}

const resetPostFinanceList = () => {
    postFinanceList = []
}


// Postfinance Buchungen für Import
let postFinanceBookingsImportList = [];

const setPostFinanceBookingsImportList = (data) => {
    postFinanceBookingsImportList = data;
}

const getPostFinanceBookingsImportList = (data) => {
    return postFinanceBookingsImportList;
}

const resetPostFinanceBookingsImportList = () => {
    postFinanceBookingsImportList = []
}



// Nab Buchungen Restlich
let postFinanceBookingsNoMatchList = [];

const setPostFinanceBookingsNoMatchList = (data) => {
    postFinanceBookingsNoMatchList = data;
}

const getPostFinanceBookingsNoMatchList = (data) => {
    return postFinanceBookingsNoMatchList;
}

const resetPostFinanceBookingsNoMatchList = () => {
    postFinanceBookingsNoMatchList = []
}

// Filiale
let filiale = ''
const getFiliale = () => {
    return filiale
}

const setFiliale = (data) => {
    resetPostFinanceList()
    resetPostFinanceBookingsImportList()
    resetPostFinanceBookingsNoMatchList()
    filiale = data
}

module.exports = {
    // Nab Bank Datei
    setPostFinanceList: setPostFinanceList,
    getPostFinanceList: getPostFinanceList,
    resetPostFinanceList: resetPostFinanceList,
    // Nab Buchungen für Import
    setPostFinanceBookingsImportList: setPostFinanceBookingsImportList,
    resetPostFinanceBookingsImportList: resetPostFinanceBookingsImportList,
    getPostFinanceBookingsImportList: getPostFinanceBookingsImportList,
    // Nab Buchungen Restlich
    setPostFinanceBookingsNoMatchList: setPostFinanceBookingsNoMatchList,
    resetPostFinanceBookingsNoMatchList: resetPostFinanceBookingsNoMatchList,
    getPostFinanceBookingsNoMatchList: getPostFinanceBookingsNoMatchList,
    // Filiale
    getFiliale: getFiliale,
    setFiliale: setFiliale
}