import { Typography } from "@mui/material";
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";

import Fab from "@mui/material/Fab";

import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import CSVReaderOne from "../CSVReader";

import { runConvertion } from "./../../functions/BankParser/Nab/BankNabFunctions";
import {
  setOpList,
  resetOpList,
  setNabList,
  resetNabList,
  getNabBookingsMatchingBillingNumberList,
  getNabBookingsImportList,
  getNabBookingsNoMatchList,
  setFiliale,
} from "./../../functions/BankParser/Nab/getSetReset";
import {exportToExcel} from '../../functions/BankParser/excelExport'

//import { CSVReader } from 'react-papaparse'

const BankNab = () => {
  const [filiale, setFilialeHandler] = React.useState("");

  const timeStamp = moment().unix();

  const handleFiliale = (event) => {
    setFilialeHandler(event.target.value);
    setFiliale(event.target.value);
  };

  const [
    nabBookingsMatchingBillingNumberList,
    setNabBookingsMatchingBillingNumberList,
  ] = useState([]);
  const [nabBookingsImportList, setNabBookingsImportList] = useState([]);
  const [nabBookingsNoMatchList, setNabBookingsNoMatchList] = useState([]);

  let dataSet = [];

  const convertData = () => {
    runConvertion();
    getData();
  };

  const getData = () => {
    setNabBookingsMatchingBillingNumberList(
      getNabBookingsMatchingBillingNumberList()
    );
    setNabBookingsImportList(getNabBookingsImportList());
    setNabBookingsNoMatchList(getNabBookingsNoMatchList());
  };

  return (
    <div>
      <Typography variant="h1">Neue Aargauer Bank CSV Dateien</Typography>
      <Box display="flex" flexDirection="column" className="bank-inner">
        <FormControl className="form-control">
          <InputLabel id="demo-simple-select-label">Filiale</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filiale}
            onChange={handleFiliale}
          >
            <MenuItem value={"kafishop"}>Kafi-Shop</MenuItem>
            <MenuItem value={"jurastore"}>Jura Store</MenuItem>
          </Select>
        </FormControl>
        {/*<CSVReaderOne name="OP-Liste" setDataFunction={setOpList} resetDataFunction={resetOpList} encoding="utf-8"/>*/}
        <CSVReaderOne
          name="NAB CSV"
          setDataFunction={setNabList}
          resetDataFunction={resetNabList}
          encoding="utf-8"
        />
        <Button
          variant="contained"
          component="label"
          className="button"
          color="primary"
          onClick={() => convertData()}
        >
          Dateien Konvertieren
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" className="box-download">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) =>
            exportToExcel(
              nabBookingsImportList,
              `${filiale}_NAB_Buchungen_für_import${timeStamp}`
            )
          }
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>NAB Buchungen {filiale} - Import</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) =>
            exportToExcel(
              nabBookingsNoMatchList,
              `${filiale}_NAB_ohne_Rechnungsnummer_Manuell${timeStamp}`
            )
          }
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>NAB Zahlungseingänge {filiale} - Manuell</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default BankNab;
