const abacusCsvHeader = ['Record Nr', 'Version', 'Datum','Konto',	'Gegenkonto',	'Text1'	,'Betrag'	,'Text2' ,'SH',	'Buchungsebene 1',	'Gegenseite Buchungsebene 1',	'Belegnummer'	, 'Kurs',	'Kursart'	, 'FWBetrag' ,'Sammelbuchungs Identifier',	'Spec1',	'Applikationsidentifikation',	'Reserve'	,'Valuta Datum', 'Sam Position',	'Reserve',	'Mandant Nr',	'ISO',	'ISO2',	'Menge',	'Ansatz',	'Buchungsebene 2',	'Gegenseite Buchungsebene 2',	'Fond1'	, 'Fond2', 'Reserve',	'Reserve',	'Reserve',	'Codefeld',	'Mwst Code',	'Mwst Satz',	'Mwst Incl',	'Mwst Methode',	'Mwst Land',	'Mwst Koeff',	'Mwst Konto',	'Mwst Gegenkonto',	'Mwst SH',	'Mwst Betrag',	'Mwst FW Betrag',	'Mwst Betrag Rest',	'Mwst FW Betrag Rest',	'Reserve',	'Mwst Typ',	'Reserve',	'Reserve',	'Reserve',	'Geschäftsbereich',	'Soll Ist',	'HabenVerdSamBetrag',	'HabenVerdSamBetragFW',	'Euro Koeff1',	'Euro Koeff2',	'Intercompany',	'Kurs2',	'Konsolidierungscode',	'Buchungsebene 3',	'Gegenseite Buchungsebene 3' ]


const abacusCsvBody = {
  'Record Nr': '',
  'Version': 'J',
  'Datum': '',
  'Konto': '',
  'Gegenkonto': '',
  'Text1': '',
  'Betrag': '',
  'Text2': '',
  'SH': 'H',
  'Buchungsebene 1': '0',
  'Gegenseite Buchungsebene 1': '100',
  'Belegnummer': '',
  'Kurs': '0',
  'Kursart': '',
  'FWBetrag': '0',
  'Sammelbuchungs Identifier': '',
  'Spec1': '',
  'Applikationsidentifikation': 'F',
  'Reserve': '',
  'Valuta Datum': '',
  'Sam Position': '0',
  'Reserve1': '',
  'Mandant Nr': '5080',
  'ISO': 'CHF',
  'ISO2': 'CHF',
  'Menge': '0',
  'Ansatz': '0',
  'Buchungsebene 2': '0',
  'Gegenseite Buchungsebene 2': '0',
  'Fond1': '11',
  'Fond2': '0',
  'Reserve2': '',
  'Reserve3': '',
  'Reserve4': '',
  'Codefeld': '',
  'Mwst Code': '200',
  'Mwst Satz': '0',
  'Mwst Incl': 'I',
  'Mwst Methode': '1',
  'Mwst Land': 'CH',
  'Mwst Koeff': '100',
  'Mwst Konto': '?',
  'Mwst Gegenkonto': '?',
  'Mwst SH': '2',
  'Mwst Betrag': '',
  'Mwst FW Betrag': '0',
  'Mwst Betrag Rest': '0',
  'Mwst FW Betrag Rest': '0',
  'Reserve5': '0',
  'Mwst Typ': '1',
  'Reserve6': '0',
  'Reserve7': '0',
  'Reserve8': '0',
  'Geschäftsbereich': '0',
  'Soll Ist': '0',
  'HabenVerdSamBetrag': '0',
  'HabenVerdSamBetragFW': '0',
  'Euro Koeff1': '1',
  'Euro Koeff2': '1',
  'Intercompany': '0',
  'Kurs2': '0',
  'Konsolidierungscode': '',
  'Buchungsebene 3': '0',
  'Gegenseite Buchungsebene 3': '0' }

  const abacusCsvBodyTurnover = {
  'Record Nr': '',
  'Version': 'J',
  'Datum': '',
  'Konto': '',
  'Gegenkonto': '',
  'Text1': '',
  'Betrag': '',
  'Text2': '',
  'SH': 'H',
  'Buchungsebene 1': '0',
  'Gegenseite Buchungsebene 1': '100',
  'Belegnummer': '',
  'Kurs': '0',
  'Kursart': '',
  'FWBetrag': '0',
  'Sammelbuchungs Identifier': '',
  'Spec1': '',
  'Applikationsidentifikation': 'F',
  'Reserve': '',
  'Valuta Datum': '',
  'Sam Position': '0',
  'Reserve1': '',
  'Mandant Nr': '5080',
  'ISO': 'CHF',
  'ISO2': 'CHF',
  'Menge': '0',
  'Ansatz': '0',
  'Buchungsebene 2': '0',
  'Gegenseite Buchungsebene 2': '0',
  'Fond1': '11',
  'Fond2': '0',
  'Reserve2': '',
  'Reserve3': '',
  'Reserve4': '',
  'Codefeld': '',
  'Mwst Code': '200',
  'Mwst Satz': '0',
  'Mwst Incl': 'I',
  'Mwst Methode': '1',
  'Mwst Land': 'CH',
  'Mwst Koeff': '0',
  'Mwst Konto': '0',
  'Mwst Gegenkonto': '0',
  'Mwst SH': '2',
  'Mwst Betrag': '0',
  'Mwst FW Betrag': '0',
  'Mwst Betrag Rest': '0',
  'Mwst FW Betrag Rest': '0',
  'Reserve5': '0',
  'Mwst Typ': '1',
  'Reserve6': '0',
  'Reserve7': '0',
  'Reserve8': '0',
  'Geschäftsbereich': '0',
  'Soll Ist': '0',
  'HabenVerdSamBetrag': '0',
  'HabenVerdSamBetragFW': '0',
  'Euro Koeff1': '1',
  'Euro Koeff2': '1',
  'Intercompany': '0',
  'Kurs2': '0',
  'Konsolidierungscode': '',
  'Buchungsebene 3': '0',
  'Gegenseite Buchungsebene 3': '0' }

  const abacusCsvBodyNoTurnover = {
    'Record Nr': '',
    'Version': 'J',
    'Datum': '',
    'Konto': '',
    'Gegenkonto': '',
    'Text1': '',
    'Betrag': '',
    'Text2': '',
    'SH': 'H',
    'Buchungsebene 1': '0',
    'Gegenseite Buchungsebene 1': '0',
    'Belegnummer': '',
    'Kurs': '0',
    'Kursart': '',
    'FWBetrag': '0',
    'Sammelbuchungs Identifier': '',
    'Spec1': '',
    'Applikationsidentifikation': 'F',
    'Reserve': '',
    'Valuta Datum': '',
    'Sam Position': '0',
    'Reserve1': '',
    'Mandant Nr': '5080',
    'ISO': 'CHF',
    'ISO2': 'CHF',
    'Menge': '0',
    'Ansatz': '0',
    'Buchungsebene 2': '0',
    'Gegenseite Buchungsebene 2': '0',
    'Fond1': '11',
    'Fond2': '0',
    'Reserve2': '',
    'Reserve3': '',
    'Reserve4': '',
    'Codefeld': '',
    'Mwst Code': '',
    'Mwst Satz': '0',
    'Mwst Incl': '',
    'Mwst Methode': '1',
    'Mwst Land': '',
    'Mwst Koeff': '0',
    'Mwst Konto': '0',
    'Mwst Gegenkonto': '0',
    'Mwst SH': '0',
    'Mwst Betrag': '0',
    'Mwst FW Betrag': '0',
    'Mwst Betrag Rest': '0',
    'Mwst FW Betrag Rest': '0',
    'Reserve5': '0',
    'Mwst Typ': '0',
    'Reserve6': '0',
    'Reserve7': '0',
    'Reserve8': '0',
    'Geschäftsbereich': '0',
    'Soll Ist': '0',
    'HabenVerdSamBetrag': '0',
    'HabenVerdSamBetragFW': '0',
    'Euro Koeff1': '1',
    'Euro Koeff2': '1',
    'Intercompany': '0',
    'Kurs2': '0',
    'Konsolidierungscode': '',
    'Buchungsebene 3': '0',
    'Gegenseite Buchungsebene 3': '0' }
    
    export { abacusCsvHeader, abacusCsvBody, abacusCsvBodyTurnover, abacusCsvBodyNoTurnover}