import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import axios from "axios";
import isAuth from "../../functions/auth/isAuth";

import { useSelector, useDispatch } from 'react-redux';
import { setFeedback } from './../../actions';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const FileUpload = () => {
  const [progress, setProgress] = useState(10);
  const [file, setFile] = useState({});
  const [filepath, setFilepath] = useState({});
  const [filename, setFilename] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadedFile, setUploadedFile] = useState({});

  const dispatch = useDispatch();


  const handleFileChange = (e) => {
    setFilename(e.target.files[0].name);
    var file = e.target.files[0];
    file.filePath = URL.createObjectURL(e.target.files[0]);
    setFile(file);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    await axios
      .post("api/file/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      })
      .then((res) => {
        // Clear percentage
        setTimeout(() => setUploadPercentage(0), 10000);

        const { fileName, filePath } = res.data;

        setUploadedFile({ fileName, filePath });

        dispatch(setFeedback(`Datei (${fileName}) erfolgreich hochgeladen`, true,'success'));

      })
      .catch((err) => {
        isAuth(err);
        dispatch(setFeedback(`Fehler beim Hochladen der Datei (${filename})`, true,'error'));
        setUploadPercentage(0);
      });
  };

  return (
    <Stack
      spacing={2}
      className="file-upload"
      sx={{
        m: 1,
        width: "60ch",
      }}
    >
      <Typography variant="h1">Dateien hochladen</Typography>

      <Button variant="outlined" component="label">
        Datei wählen
        <input onChange={(e) => handleFileChange(e)} type="file" hidden />
      </Button>

      <Typography className="filename">{filename}</Typography>
      {file !== {} ? <img src={file.filePath} /> : null}
      {uploadPercentage > 0 ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={uploadPercentage} />
        </Box>
      ) : null}
      {filename !== "" && (
        <Button
          variant="contained"
          component="label"
          onClick={(e) => onSubmit(e)}
        >
          Datei Hochladen
        </Button>
      )}
    </Stack>
  );
};

export default FileUpload;
