
const feedback = (state = {message: 'No Message', isOpen:false, type:'success'}, {type, payload}) => {
  switch (type) {
    case 'SET_FEEDBACK':
      return payload;
    default:
      return state;
  }
};

export default feedback;
