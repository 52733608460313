import { Typography } from "@mui/material";
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";

import Fab from "@mui/material/Fab";

import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import CSVReaderOne from "../CSVReader";

import { runConvertion } from "./../../functions/BankParser/PostFinance/BankPostFinanceFunctions";
import {
  setPostFinanceList,
  resetPostFinanceList,
  getPostFinanceBookingsImportList,
  getPostFinanceBookingsNoMatchList,
  setFiliale,
} from "./../../functions/BankParser/PostFinance/getSetReset";
import { exportToExcel } from "../../functions/BankParser/excelExport";

const BankPostFinance = () => {
  const [filiale, setFilialeHandler] = React.useState("");

  const timeStamp = moment().unix();

  const handleFiliale = (event) => {
    setFilialeHandler(event.target.value);
    setFiliale(event.target.value);
  };

  const [postFinanceBookingsImportList, setPostFinanceBookingsImportList] =
    useState([]);
  const [postFinanceBookingsNoMatchList, setPostFinanceBookingsNoMatchList] =
    useState([]);

  let dataSet = [];

  const convertData = () => {
    runConvertion();
    getData();
  };

  const getData = () => {
    setPostFinanceBookingsImportList(getPostFinanceBookingsImportList());
    setPostFinanceBookingsNoMatchList(getPostFinanceBookingsNoMatchList());
  };

  return (
    <div>
      <Typography variant="h1">PostFinance CSV Dateien</Typography>
      <Box display="flex" flexDirection="column" className="bank-inner">
        <FormControl className="form-control">
          <InputLabel id="demo-simple-select-label">Filiale</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filiale}
            onChange={handleFiliale}
          >
            <MenuItem value={"kafishop"}>Kafi-Shop</MenuItem>
            <MenuItem value={"jurastore"}>Jura Store</MenuItem>
          </Select>
        </FormControl>
        <CSVReaderOne
          name="PostFinance CSV"
          setDataFunction={setPostFinanceList}
          resetDataFunction={resetPostFinanceList}
          encoding="iso-8859-1"
        />
        <Button
          variant="contained"
          component="label"
          className="button"
          color="primary"
          onClick={() => convertData()}
        >
          Dateien Konvertieren
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" className="box-download">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) => {
            console.log(postFinanceBookingsImportList);
            exportToExcel(
              postFinanceBookingsImportList,
              `${filiale}_PostFinance_Buchungen_für_import${timeStamp}`
            );
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>PostFinance Buchungen - Import {filiale}</Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) =>
            exportToExcel(
              postFinanceBookingsNoMatchList,
              `${filiale}_PostFinance_ohne_Rechnungsnummer_Manuell${timeStamp}`
            )
          }
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>
            PostFinance Zahlungseingänge - Manuell {filiale}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default BankPostFinance;
