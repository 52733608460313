import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";

import React, { useEffect, useState } from "react";
import axios from "axios";
import isAuth from "../../functions/auth/isAuth";
import { useSelector, useDispatch } from "react-redux";
import { setFeedback } from "./../../actions";

const SlideShow = ({
  slideShowProp,
  callBackDeleteSlideShow,
  handleChange,
  selectedValue,
}) => {
  const [slideShow, setSlideShow] = useState();
  const [slideShowName, setSlideShowName] = useState();
  const [hasChanges, setHasChanges] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [slideShowDuration, setSlideShowDuration] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setSlideShow(slideShowProp);
    setSlideShowName(slideShowProp.name);
    setIsPublished(slideShowProp.published);
    setSlideShowDuration(slideShowProp.duration);
  }, []);

  const onChangeName = (value) => {
    setSlideShowName(value);
    let slideShowTemp = slideShow;
    slideShowTemp.name = value;
    setSlideShow(slideShowTemp);
    setHasChanges(true);
  };

  const changeIsPublished = () => {
    setIsPublished(!isPublished);
    let slideShowTemp = slideShow;
    slideShowTemp.published = !isPublished;
    setSlideShow(slideShowTemp);
    setHasChanges(true);
  };

  const onChangeDueration = value => {
    setSlideShowDuration(value);
    let slideShowTemp = slideShow;
    slideShowTemp.duration = value
    setSlideShow(slideShowTemp);
    setHasChanges(true);
  }

  const onClickDelete = () => {
    axios
      .delete(`/api/slide-show/${slideShow.id}`, slideShow)
      .then((res) => {
        callBackDeleteSlideShow(slideShow.id);
        dispatch(
          setFeedback(
            `SlideShow (${slideShow.id}) erfolgreich gelöscht`,
            true,
            "success"
          )
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setFeedback(
            `Löschen von SlideShow (${slideShow.id}) fehlgeschlagen`,
            true,
            "error"
          )
        );
        isAuth(err);
      });
  };

  const onClickSave = () => {
    console.log(slideShow);
    if (slideShow.id !== undefined && slideShow.id !== "") {
      axios
        .patch(`/api/slide-show/${slideShow.id}`, slideShow)
        .then((res) => {
          console.log(res.data);
          slideShowProp.name = res.data.name;
          setHasChanges(false);
          dispatch(
            setFeedback(
              `SlideShow (${slideShow.id}) erfolgreich aktualisiert`,
              true,
              "success"
            )
          );
        })
        .catch((err) => {
          isAuth(err);
          console.log(err);

          dispatch(
            setFeedback(
              `Update von SlideShow (${slideShow.id}) fehlgeschlagen`,
              true,
              "error"
            )
          );
        });
    } else {
      axios
        .post(`/api/slide-show/`, slideShow)
        .then((res) => {
          console.log(res.data);
          slideShow.id = res.data.id;
          slideShowProp.name = res.data.name;
          setHasChanges(false);
          dispatch(
            setFeedback(
              `SlideShow (${slideShow.id}) erfolgreich angelegt`,
              true,
              "success"
            )
          );
        })
        .catch((err) => {
          console.log(err);
          isAuth(err);
          dispatch(
            setFeedback(
              `Anlegen von SlideShow (${slideShow.id}) fehlgeschlagen`,
              true,
              "error"
            )
          );
        });
    }
  };

  return (
    <ListItem
      secondaryAction={
        !hasChanges ? (
          <IconButton edge="end" aria-label="delete" onClick={onClickDelete}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <IconButton edge="end" aria-label="delete" onClick={onClickSave}>
            <CheckIcon />
          </IconButton>
        )
      }
    >
      <Checkbox
        checked={isPublished}
        onChange={(e) => changeIsPublished()}
        inputProps={{ "aria-label": "controlled" }}
      />
      
      <ListItemButton>
        <TextField
          id="standard-basic"
          style={{marginRight:"20px"}}
          label=""
          variant="standard"
          value={slideShowName}
          onChange={(e) => {
            onChangeName(e.target.value);
          }}
        />
        <Slider
          size="small"
          style={{maxWidth:"150px"}}
          value={slideShowDuration}
          aria-label="Small"
          valueLabelDisplay="auto"
          onChange={(e) => onChangeDueration(e.target.value)}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SlideShow;
