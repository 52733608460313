import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Gallery from "../Gallery/Gallery";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Switch from '@mui/material/Switch';
import config from '../../config'

const SlideImage = ({ slideCallback, slide }) => {
  useEffect(() => {
    console.log(slide)
    if (slide.url) setHasImage(true);
    if (slide.image_mode === "cover") {
      setImageMode(false)
    } else {
      setImageMode(true)
      slide.image_mode = "contain"
    }
  }, []);

  const [hasImage, setHasImage] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [slideName, setSlideName] = useState("");
  const [imageMode, setImageMode] = useState("on")

  const handleChangeImage = () => {
    setHasImage(!hasImage);
    slide.url = "";
  };

  const handleShowGallery = () => {
    setShowGallery(true);
  };

  const handleImageModeChange = checked => {
    console.log(checked)
    setImageMode(checked)
    if(checked === false) {
      slide.image_mode = "cover"
    } else {
      slide.image_mode = "contain"
    }
    slideCallback(slide);
  }

  const callBackImageUrl = (imageUrl) => {
    console.log(imageUrl);
    handleChangeImage();
    slide.url = imageUrl;
    slideCallback(slide);
    setShowGallery(false);
  };

  const onChangeSlideName = (value) => {
    setSlideName(value);
    slide.name = value;
    slideCallback(slide);
  };



  return (
    <Stack
      spacing={2}
      sx={{
        m: 1,
        width: "25ch",
      }}
    >
      <TextField
        id="offer-name"
        label="Slide Name"
        variant="outlined"
        value={slideName}
        onChange={(e) => {
          onChangeSlideName(e.target.value);
        }}
      />
       <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Füllend</Typography>
        <Switch checked={imageMode} color="default" onChange={(e) => handleImageModeChange(e.target.checked)}/>
        <Typography>Passend</Typography>
      </Stack>


      {hasImage ? (
        <ImageListItem>
          <img
            className="slide-img"
            src={`${config.backendURL}/${slide.url}?w=164&h=164&fit=crop&auto=format`}
            alt={slide.name}
            loading="lazy"
          />
          <ImageListItemBar
            sx={{
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            }}
            position="top"
            actionIcon={
              <IconButton sx={{ color: "white" }} onClick={handleChangeImage}>
                <DeleteOutlineIcon />
              </IconButton>
            }
            actionPosition="left"
          />
        </ImageListItem>
      ) : (
        <Box onClick={handleShowGallery} className="slide-add-img">
          <Typography>Click to add Image</Typography>
          <AddIcon />
        </Box>
      )}
      {showGallery && <Gallery callBack={callBackImageUrl} isSelector="true" />}
    </Stack>
  );
};

export default SlideImage;
