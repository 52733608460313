import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import BankParser from './components/BankParser/BankParser';
import ShopStatistics from './components/ShopStatistics/ShopStatistics';
import Login from './components/Login/Login';
import PrivateRoute from './components/PrivateRoute';
import Gallery from './components/Gallery/GalleryOverview';
import SlideShow from './components/SlideShow/SlideShowOverview';
import NavComplete from './components/Navigation/NavComplete';


const Router = () => {



  return (
    <BrowserRouter>
      <div className="main-content">
        <App />
        <Switch>
          <PrivateRoute exact path="/bank-parser" component={BankParser} />
          <PrivateRoute exact path="/shop-statistics" component={ShopStatistics} />
          <PrivateRoute exact path="/tv-remote" component={SlideShow} />
          <PrivateRoute exact path="/gallery" component={Gallery} />
          <Route exact path="/login" component={Login} />
          <Route path="/" component={BankParser} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default Router;
