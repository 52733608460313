import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import isAuth from "../../functions/auth/isAuth";
import List from "@mui/material/List";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import SlideShow from "./SlideShow";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { useSelector, useDispatch } from "react-redux";
import { setFeedback } from "./../../actions";

const SlideShowList = () => {
  const [slideShows, setSlideShows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("api/slide-show/")
      .then((resp) => {
        console.log(resp.data);
        setSlideShows(resp.data);
      })
      .catch((err) => {
        isAuth(err);
        dispatch(
          setFeedback(
            `Fehler beim Laden der Slide-Shows vom Server`,
            true,
            "error"
          )
        );
        console.log(err);
      });
  }, []);

  const callBackDeleteSlideShow = (slideShowTempId) => {
    console.log(slideShowTempId);
    const slideShowsTemp = slideShows.filter(
      (slideShow) => slideShow.id !== slideShowTempId
    );
    setSlideShows(slideShowsTemp);
  };

  const addSlideShow = () => {
    setSlideShows([...slideShows, {}]);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        m: 1,
        width: "50ch",
      }}
    >
      <Typography variant="h1">Slide Shows</Typography>
      <List>
        <Typography>Published?</Typography>

        {slideShows.map((slideShow) => (
          <SlideShow
            slideShowProp={slideShow}
            callBackDeleteSlideShow={callBackDeleteSlideShow}
            key={slideShow.id}
          />
        ))}
      </List>
      <Fab color="primary" aria-label="add" onClick={addSlideShow}>
        <AddIcon />
      </Fab>
    </Stack>
  );
};

export default SlideShowList;
