export const exportConfig = {
  bookingTextMaxLength: 75,
  jurastore: {
    spesen: 6901,
    nab: 1021,
    postfinance: 1015,
    transferPostcard: 10950,
    transferEc: 10951,
    transferTwint: 10952,
    transferVisa: 10953,
    transferMastercard: 10954,
    transferAmex: 10955,
    transferPaypal: 10956,
    transferPayone: 10957,
    gegenseitigeBuchungsseite: 100,
  },
  kafishop: {
    spesen: 6900,
    nab: 1020,
    postfinance: 1010,
    transferPostcard: 10900,
    transferEc: 10901,
    transferTwint: 10902,
    transferVisa: 10903,
    transferMastercard: 10904,
    transferAmex: 10905,
    transferPaypal: 10906,
    transferPayone: 10907,
    gegenseitigeBuchungsseite: 200,
  },
}