import React, { useState, useNavigate, useLocation } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { setFeedback } from './../../actions';



const LoginForm = () => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const dispatch = useDispatch();


  const submit = (e) => {
    e.preventDefault();
    axios
      .post('/api/auth/login', {
        username: username,
        password: password,
      })
      .then(function (response) {
        localStorage.setItem('isAuthenticated', 'true');
        window.location.pathname = '/bank-parser';
      })
      .catch(function (error) {
        dispatch(setFeedback(`Login fehlgeschlagen`, true,'error'));
        
        console.log(error);
      });
  };

  return (
    <div className="login-wrapper">
      <div className={'login'}>
        <Card>
          <CardContent className="display-flex justify-center flex-dir-column">
            <Typography>Login</Typography>
            <form onSubmit={submit} className="display-flex justify-center flex-dir-column">

              <TextField
                id="username"
                label="Username"
                variant="outlined"
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
              />
              <TextField
                type="password"
                id="password"
                label="Password"
                variant="outlined"
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />

              <Button type="submit" variant="contained">
                Login
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default LoginForm;
