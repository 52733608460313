import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";
import React, { useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import { setFiliale } from "../../functions/BankParser/Paypal/getSetReset";
import { exportToExcel } from "../../functions/BankParser/excelExport";


const abacusExampleRow = {
  "Record Nr": "",
  Version: "J",
  Datum: "",
  Konto: "",
  Gegenkonto: "",
  Text1: "",
  Betrag: "",
  Text2: "",
  SH: "H",
  "Buchungsebene 1": "",
  "Gegenseite Buchungsebene 1": "",
  Belegnummer: "",
  Kurs: "0",
  Kursart: "",
  FWBetrag: "0",
  "Sammelbuchungs Identifier": "",
  Spec1: "",
  Applikationsidentifikation: "F",
  Reserve: "",
  "Valuta Datum": "",
  "Sam Position": "0",
  Reserve: "",
  "Mandant Nr": "",
  ISO: "CHF",
  ISO2: "CHF",
  Menge: "0",
  Ansatz: "0",
  "Buchungsebene 2": "0",
  "Gegenseite Buchungsebene 2": "0",
  Fond1: "",
  Fond2: "",
  Reserve: "",
  Reserve: "",
  Reserve: "",
  Codefeld: "",
  "Mwst Code": "",
  "Mwst Satz": "",
  "Mwst Incl": "I",
  "Mwst Methode": "1",
  "Mwst Land": "CH",
  "Mwst Koeff": "100",
  "Mwst Konto": "",
  "Mwst Gegenkonto": "",
  "Mwst SH": "1",
  "Mwst Betrag": "",
  "Mwst FW Betrag": "0",
  "Mwst Betrag Rest": "0",
  "Mwst FW Betrag Rest": "0",
  Reserve: "0",
  "Mwst Typ": "1",
  Reserve: "0",
  Reserve: "0",
  Reserve: "0",
  Geschäftsbereich: "0",
  "Soll Ist": "0",
  HabenVerdSamBetrag: "0",
  HabenVerdSamBetragFW: "0",
  "Euro Koeff1": "1",
  "Euro Koeff2": "1",
  Intercompany: "0",
  Kurs2: "0",
  Konsolidierungscode: "",
  "Buchungsebene 3": "0",
  "Gegenseite Buchungsebene 3": "0",
};

const getKonto = (habenkonto) => {
  if(habenkonto === "3201") {
    return "3310"
  }
  if(habenkonto === "3400") {
    return "3320"
  }
  return ""
}

const getMwstKonto = (buchungstext) => {
  if(buchungstext.includes("QUI")) {
    return "2200"
  }
  return ""
}

const getMwstCode = (buchungstext, mwstSatz) => {
  if(buchungstext.includes("QUI") && mwstSatz === "7,7") {
    return "311"
  }
  if(buchungstext.includes("QUI") && mwstSatz === "2,5") {
    return "312"
  }
  return ""
}

export default function CashPoint() {
  const [filiale, setFilialeHandler] = useState("");
  const inputData = useRef(null);
  const outputData = useRef(null);
  outputData.current = [];
  const timeStamp = moment().unix();

  const handleFiliale = (event) => {
    setFilialeHandler(event.target.value);
    setFiliale(event.target.value);
  };

  const convertData = (e) => {
    inputData.current.forEach((row, index) => {
      const taxAmount = (
        parseFloat(row[4].replace(",", ".")) -
        parseFloat(row[5].replace(",", "."))
      ).toFixed(2)
        .replace(".", ",");
      outputData.current.push({
        ...abacusExampleRow,
        "Record Nr": index + 1,
        Datum: row[0],
        Konto: getKonto(row[8]),
        Gegenkonto: row[9],
        Text1: row[2],
        Betrag: row[5],
        Text2: row[1],
        "Buchungsebene 1": "",
        "Gegenseite Buchungsebene 1": "",
        "Mandant Nr": filiale === "jurastore" ? "8002" : "5080",
        Fond1: row[15],
        Fond2: "",
        "Mwst Code": getMwstCode(row[2], row[7]),
        "Mwst Satz": row[7],
        "Mwst Konto": getMwstKonto(row[2]),
        "Mwst Gegenkonto": getKonto(row[8]),
        "Mwst Betrag": taxAmount,
      });
    });
  };

  const getInputData = (e) => {
    readXlsxFile(e.target.files[0]).then((rows, index) => {
      inputData.current = rows.slice(2);
    });
  };

  return (
    <div>
      <Typography variant="h1">Kasse Onexio - Abacus Dateien</Typography>
      <Box display="flex" flexDirection="column" className="bank-inner">
        <FormControl className="form-control">
          <InputLabel id="demo-simple-select-label">Filiale</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filiale}
            onChange={handleFiliale}
          >
            <MenuItem value={"kafishop"}>Kafi-Shop</MenuItem>
            <MenuItem value={"jurastore"}>Jura Store</MenuItem>
          </Select>
        </FormControl>

        <input
          type="file"
          id="avatar"
          name="avatar"
          accept="xlsx"
          onChange={(e) => {
            getInputData(e);
          }}
        />
        <Button
          variant="contained"
          component="label"
          className="button"
          color="primary"
          onClick={() => convertData()}
        >
          Dateien Konvertieren
        </Button>
      </Box>

      <Box display="flex" flexDirection="column" className="box-download">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          onClick={(e) => {
            exportToExcel(
              outputData.current,
              `Kasse_${filiale}_Buchungen_für_Import${timeStamp}`
            );
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <ArrowDownwardIcon />
          </IconButton>
          <Typography>Kasse {filiale} Buchungen - Import</Typography>
        </Box>
      </Box>
    </div>
  );
}
