import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import axios from "axios";
import isAuth from "../../functions/auth/isAuth";
import FormControl from "@mui/material/FormControl";
import SlideImage from "./SlideImage";
import SlideOffer from "./SlideOffer";
import InputLabel from "@mui/material/InputLabel";
import { useSelector, useDispatch } from "react-redux";
import { setFeedback } from "./../../actions";
import { TextField } from "@mui/material";


const SlideAccordion = ({ slide, callBackDeleteSlide }) => {
  useEffect(() => {
    setSlideName(slide.name);
    setType(slide.type);
    console.log(slide);
  }, []);

  const [slideName, setSlideName] = useState("");
  const [type, setType] = React.useState("");
  const dispatch = useDispatch();

  const handleChangeType = (value) => {
    setType(value);
    slide.type = value;
  };

  const slideCallback = (slideInput) => {
    slide = slideInput;
    setSlideName(slideInput.name);
  };

  const saveSlide = () => {
    if (slide.id !== undefined && slide.id !== "") {
      axios
        .patch(`/api/slide/${slide.id}`, slide)
        .then((res) => {
          dispatch(
            setFeedback(
              `Slide (${slide.id}) erfolgreich aktualisiert`,
              true,
              "success"
            )
          );
        })
        .catch((err) => {
          dispatch(
            setFeedback(
              `Fehler beim Aktualisieren von Slide (${slide.id})`,
              true,
              "error"
            )
          );
          console.log(err);
        });
    } else {
      axios
        .post(`/api/slide/`, slide)
        .then((res) => {
          slide.id = res.data.id;
          dispatch(
            setFeedback(
              `Slide (${slide.id}) erfolgreich angelegt`,
              true,
              "success"
            )
          );
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            setFeedback(
              `Fehler beim Anlegen von Slide (${slide.id})`,
              true,
              "error"
            )
          );
        });
    }
  };

  const deleteSlide = () => {
    axios
      .delete(`/api/slide/${slide.id}`, slide)
      .then((res) => {
        callBackDeleteSlide(slide.id);
        dispatch(
          setFeedback(
            `Slide (${slide.id}) erfolgreich gelöscht`,
            true,
            "success"
          )
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setFeedback(
            `Fehler beim Löschen von Slide (${slide.id})`,
            true,
            "error"
          )
        );
      });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="display-flex align-items-center justify-between width-100">
          <Typography>{slideName}</Typography>{" "}
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div>
          {/*{type === undefined || type === '' ? (*/}
          <Stack
            spacing={2}
            sx={{
              m: 1,
              width: "25ch",
            }}
          >
            <FormControl>
              <InputLabel id="type">Typ</InputLabel>
              <Select
                labelId="type"
                id="type"
                value={type}
                label="Type"
                onChange={(e) => handleChangeType(e.target.value)}
              >
                <MenuItem value={"image"}>Bild</MenuItem>
                <MenuItem value={"offer"}>Angebot</MenuItem>
              </Select>
            </FormControl>
            
          </Stack>
          {/*) : null}*/}
          {slide.type === "image" && (
            <SlideImage slideCallback={slideCallback} slide={slide} />
          )}
          {slide.type === "offer" && (
            <SlideOffer slideCallback={slideCallback} slide={slide} />
          )}
        </div>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={saveSlide}>
            Save
          </Button>
          <Button variant="outlined" onClick={deleteSlide} color="error">
            Delete
          </Button>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SlideAccordion;
