
import React from 'react';
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TvIcon from '@mui/icons-material/Tv';
import ImageIcon from '@mui/icons-material/Image';
import { NavLink} from "react-router-dom";
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import BankParser from '../BankParser/BankParser'
import ShopStatistics from '../ShopStatistics/ShopStatistics'

import Divider from '@mui/material/Divider';



function Sidebar() {

    return (
        <div>
            <Drawer variant="permanent" className="sidebar">
            <Divider />
            <List className="sidebar-list">
            <NavLink to="/bank-parser" className="nav-link" activeClassName="active-link" style={{ textDecoration: 'none' }}>
                <ListItem button>
                <ListItemIcon>
                    <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Bank Dateien" />
                </ListItem>
            </NavLink>
            <NavLink to="/tv-remote" className="nav-link" activeClassName="active-link" style={{ textDecoration: 'none' }}>
                <ListItem button>
                    <ListItemIcon>
                        <TvIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fernbedienung" />
                </ListItem>
            </NavLink>
            <NavLink to="/gallery" className="nav-link" activeClassName="active-link" style={{ textDecoration: 'none' }}>
                <ListItem button>
                    <ListItemIcon>
                        <ImageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Galerie" />
                </ListItem>
            </NavLink>
            <NavLink to="/users" className="nav-link" activeClassName="active-link" style={{ textDecoration: 'none' }}>
                <ListItem button>
                    <ListItemIcon>
                        <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nutzer" />
                </ListItem>
            </NavLink>
            {/*<NavLink to="/shop-statistics" className="nav-link" activeClassName="active-link" style={{ textDecoration: 'none' }}>
                <ListItem button>
                <ListItemIcon>
                    <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Shop Statistik" />
                </ListItem>
            </NavLink>
            */}
            </List>
            </Drawer>
        </div>
    );

}


export default Sidebar;
