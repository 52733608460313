// Paypal Datei
let paypalList = [];

const setPaypalList = (data) => {
    paypalList = data;
}

const getPaypalList = (data) => {
    return paypalList;
}

const resetPaypalList = () => {
    paypalList = []
}

// Paypal Kommisionen
let paypalBookingImportList = [];

const setPaypalBookingImportList = (data) => {
    paypalBookingImportList = data;
}

const getPaypalBookingImportList = (data) => {
    return paypalBookingImportList;
}

const resetPaypalBookingImportList = () => {
    paypalBookingImportList = []
}

// PayPal Restlich
let paypalBookingsManuellList = [];

const setPaypalBookingsManuellList = (data) => {
    paypalBookingsManuellList = data;
}

const getPaypalBookingsManuellList = (data) => {
    return paypalBookingsManuellList;
}

const resetPaypalBookingsManuellList = () => {
    paypalBookingsManuellList = []
}

// Filiale
let filiale = ''
const getFiliale = () => {
    return filiale
}

const setFiliale = (data) => {
    resetPaypalList()
    resetPaypalBookingImportList()
    resetPaypalBookingsManuellList()
    filiale = data
}

module.exports = {
    // Paypal Datei
    setPaypalList: setPaypalList,
    getPaypalList: getPaypalList,
    resetPaypalList: resetPaypalList,
    // Paypal Buchungen für Import
    setPaypalBookingImportList: setPaypalBookingImportList,
    getPaypalBookingImportList: getPaypalBookingImportList,
    resetPaypalBookingImportList: resetPaypalBookingImportList,
    // Paypal Buchungen Manuell
    setPaypalBookingsManuellList: setPaypalBookingsManuellList,
    getPaypalBookingsManuellList: getPaypalBookingsManuellList,
    resetPaypalBookingsManuellList: resetPaypalBookingsManuellList,
    // Filiale
    getFiliale: getFiliale,
    setFiliale: setFiliale
}