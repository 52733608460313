import React from 'react'
import Sidebar from './Sidebar'
import TopBar from './TopBar'

const NavComplete = () => {
    return (
        <div>
        <TopBar />
        <Sidebar />
        </div>
    )
}

export default NavComplete