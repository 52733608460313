import {
  getPostFinanceList,
  setPostFinanceBookingsImportList,
  setPostFinanceBookingsNoMatchList,
  getFiliale
} from './getSetReset'
import { abacusCsvHeader, 
  abacusCsvBodyTurnover,
  abacusCsvBodyNoTurnover, } from '../Abacus/helper'
import { exportConfig } from '../export-config'

let postFinanceBookingsNoMatchList = []
let postFinanceBookingsImportList = []
let postFinanceList = []

const checkPostFinanceBookingsImportList = () => {
  const filiale = getFiliale()
  postFinanceList = getPostFinanceList()

  for (let postFinanceListItemIndex = 0; postFinanceListItemIndex < postFinanceList.length; postFinanceListItemIndex++) {
    if (postFinanceList[postFinanceListItemIndex][0].toLowerCase().includes("buchungsdatum")) {
      // CSV Start (nabListItemIndex + 1) und CSV Ende (nabList.length -1) aus nabList entfernen
      postFinanceList = postFinanceList.slice(postFinanceListItemIndex + 1, postFinanceList.length - 3)
    }
  }

  let postTransfer = 0;
  let bank = 0;
  let bankspesen = 0;

  if (filiale === 'kafishop') {
    postTransfer = exportConfig.kafishop.transferPostcard //10900;
    bank = exportConfig.kafishop.postfinance;
    bankspesen = exportConfig.kafishop.spesen;
  }
  if (filiale === 'jurastore') {
    postTransfer = exportConfig.jurastore.transferPostcard //10950;
    bank = exportConfig.jurastore.postfinance; //1015;
    bankspesen = exportConfig.jurastore.spesen; //6900;
  }

  postFinanceList.forEach((payment, index) => {
    //const dateTemp = payment[0].split("-")
    //const date = `${dateTemp[2]}.${dateTemp[1]}.${dateTemp[0]}`
    //const date = `${dateTemp[0]}`
    const date = payment[0]
    const bookingText = payment[1]
    const amountPositiv = payment[2]
    const amountNegative = payment[3].replaceAll('-', '')
    const newBookingObj = { ...abacusCsvBodyNoTurnover }
    const newKomissionObj = { ...abacusCsvBodyTurnover }

    if (filiale === 'kafishop') {
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.kafishop.gegenseitigeBuchungsseite;

    }
    if (filiale === 'jurastore') {
      newKomissionObj['Gegenseite Buchungsebene 1'] = exportConfig.jurastore.gegenseitigeBuchungsseite;

    }


    if (bookingText.toLowerCase().includes("preis") && bookingText.toLowerCase().includes("eft/pos")) {
      newKomissionObj['Datum'] = date;
      newKomissionObj['Konto'] = bank;
      newKomissionObj['Gegenkonto'] = bankspesen;
      newKomissionObj['Text1'] = `Kommision Postcard Filiale: ${amountNegative}`;
      newKomissionObj['Betrag'] = amountNegative


      postFinanceBookingsImportList.push(newKomissionObj);
    } else if (bookingText.toLowerCase().includes("gutschrift eft/pos")) {

      newBookingObj['Datum'] = date;
      newBookingObj['Konto'] = postTransfer;
      newBookingObj['Gegenkonto'] = bank;
      newBookingObj['Text1'] = `Gutschrift Postcard Filiale:  ${amountPositiv}, ${date}`;
      newBookingObj['Betrag'] = amountPositiv
      newBookingObj['Mwst Incl'] = ''


      postFinanceBookingsImportList.push(newBookingObj);
    } else if (bookingText.toLowerCase().includes("gutschrift e-payment") && bookingText.toLowerCase().includes("postfinance e-finance")) {

      newBookingObj['Datum'] = date;
      newBookingObj['Konto'] = postTransfer;
      newBookingObj['Gegenkonto'] = bank;
      newBookingObj['Text1'] = `Gutschrift E-Payment E-Finance Online-Shop: ${amountPositiv}, ${date}`;
      newBookingObj['Betrag'] = amountPositiv
      newBookingObj['Mwst Incl'] = ''


      postFinanceBookingsImportList.push(newBookingObj);
    } else if (bookingText.toLowerCase().includes("preis") && bookingText.toLowerCase().includes("e-payment") && bookingText.toLowerCase().includes("postfinance e-finance")) {

      newKomissionObj['Datum'] = date;
      newKomissionObj['Konto'] = bank;
      newKomissionObj['Gegenkonto'] = bankspesen;
      newKomissionObj['Text1'] = `Kommision E-Payment E-Finance Online-Shop: ${amountNegative}, ${date}`;
      newKomissionObj['Betrag'] = amountNegative


      postFinanceBookingsImportList.push(newKomissionObj);
    } else if (bookingText.toLowerCase().includes("gutschrift e-payment") && bookingText.toLowerCase().includes("postfinance card")) {

      newBookingObj['Datum'] = date;
      newBookingObj['Konto'] = postTransfer;
      newBookingObj['Gegenkonto'] = bank;
      newBookingObj['Text1'] = `Gutschrift E-Payment Postcard Online-Shop: + ${amountPositiv}, ${date}`;
      newBookingObj['Betrag'] = amountPositiv
      newBookingObj['Mwst Incl'] = ''


      postFinanceBookingsImportList.push(newBookingObj);
    } else if (bookingText.toLowerCase().includes("preis") && bookingText.toLowerCase().includes("e-payment") && bookingText.toLowerCase().includes("postfinance card")) {

      newKomissionObj['Datum'] = date;
      newKomissionObj['Konto'] = bank;
      newKomissionObj['Gegenkonto'] = bankspesen;
      newKomissionObj['Text1'] = `Kommision E-Payment Postcard Online-Shop: ${amountNegative}, ${date}`;
      newKomissionObj['Betrag'] = amountNegative

      postFinanceBookingsImportList.push(newKomissionObj);
    } else {
      if (!(amountNegative === "")) {
        const newBookingObj = { ...abacusCsvBodyTurnover }
        newBookingObj['Datum'] = date;
        newBookingObj['Text1'] = bookingText;
        newBookingObj['Betrag'] = amountNegative

        postFinanceBookingsNoMatchList.push(newBookingObj);

      } else {

        const newBookingObj = { ...abacusCsvBodyTurnover }
        newBookingObj['Datum'] = date;
        newBookingObj['Text1'] = bookingText;
        newBookingObj['Betrag'] = amountPositiv;
        newBookingObj['Mwst Code'] = '';
        newBookingObj['Mwst Satz'] = '';

        postFinanceBookingsNoMatchList.push(newBookingObj);
      }
    }
    
    

  // postFinanceBookingsImportList.splice(0, 0, abacusCsvHeader);
  // postFinanceBookingsNoMatchList.splice(0, 0, abacusCsvHeader);

  })


  postFinanceBookingsImportList = postFinanceBookingsImportList.map((booking, index) => {
    booking['Record Nr'] = index + 1
    booking['Text1'] = booking['Text1'].substring(0, (booking['Text1'].length -1) < exportConfig.bookingTextMaxLength ? (booking['Text1'].length -1) : exportConfig.bookingTextMaxLength )
    booking['Text2'] = booking['Text2'].substring(0, (booking['Text2'].length -1) < exportConfig.bookingTextMaxLength ? (booking['Text2'].length -1) : exportConfig.bookingTextMaxLength )

    return booking
  })
  postFinanceBookingsNoMatchList = postFinanceBookingsNoMatchList.map((booking, index) => {
    booking['Record Nr'] = index + 1
    return booking
  })


  // postFinanceBookingsImportList.splice(0, 0, abacusCsvHeader);
  // postFinanceBookingsNoMatchList.splice(0, 0, abacusCsvHeader);


  console.log(postFinanceList)
  console.log(postFinanceBookingsImportList)
  console.log(postFinanceBookingsNoMatchList)
  setPostFinanceBookingsImportList(postFinanceBookingsImportList)
  setPostFinanceBookingsNoMatchList(postFinanceBookingsNoMatchList)


}

const runConvertion = () => {
  checkPostFinanceBookingsImportList()
  postFinanceList = []
  postFinanceBookingsImportList = []
  postFinanceBookingsNoMatchList = []
}

export { runConvertion }

