import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
const theme = createTheme({
    palette: {
        primary: {
          main: grey[900],
        },
        secondary: {
          main: grey[900],
          contrastText: '#000000'
        }
      },
    components: {
      Alert: {

      }
    }
})

export default theme;
