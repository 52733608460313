export default function isAuth(err) {
  try {
    if (err.response.status === 401) {
      localStorage.setItem("isAuthenticated", "false");
      window.location.pathname = "/login";
    }
  } catch (err) {
    console.log(err)
    window.location.pathname = "/login";
  }

}
